@media screen and (max-width: 768px) {
  .request-grid {
    grid-template-columns: 1fr;
  }
  .top-grid {
    grid-template-columns: 1fr;
  }
  .top-inner-grid {
    grid-template-columns: 2fr 1fr;
  }
  .shared-grid {
    grid-template-columns: 2fr 1fr;
  }
}
@media screen and (max-width: 599px) {
  .menu-item {
    position: relative;
  }
  .user_grid {
    display: block;
  }
  .sidebar {
    position: fixed;
    top: 50px;
    left: 0;
    transform: translateX(-100%);
    height: calc(100vh - 50px);
  }
  .sidebar.active {
    transform: translateX(0);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  .toggle-bar {
    display: block;
  }
  .toggle-bar.active {
    display: block;
  }
  header {
    height: 50px !important;
  }
  header > div {
    height: 100%;
  }
  .secret_key_bar {
    grid-template-columns: repeat(2, 1fr);
  }
  .menu-item {
    position: relative;
  }
  .top-bar-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .top-bar-grid > div:nth-child(1) {
    flex: 1;
  }
}
@media screen and (max-width: 400px) {
  .top-inner-grid {
    grid-template-columns: 1fr;
  }
  .top-icon-grid {
    justify-content: flex-start;
  }
  .toggle-buttons {
    flex-direction: column;
    align-items: start;
  }
  .secret_key_bar {
    grid-template-columns: 1fr;
  }
}
