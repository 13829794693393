* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "NunitoSans";
}
@font-face {
  font-family: "NunitoSans";
  src: url(../fonts/NunitoSans.ttf);
}
:root {
  --primary: #d95114;
  --secondary: #09838138;
  --transition: all 0.1s linear;
}
html {
  scroll-behavior: smooth;
}
body {
  width: 100%;
  height: auto;
  overflow-x: hidden;
  color: #000;
}
img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
body.dark {
  background: #000;
}
h1 {
  margin: 0;
  font-size: 1.5rem;
}
h2 {
  margin: 0;
  font-size: 1.25rem;
}
p {
  margin: 0;
}
svg {
  font-size: 1.5rem;
}
ul {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
.menu-item a {
  color: #000 !important;
}
.primary-text {
  color: var(--primary);
}
.light-bg {
  background: var(--secondary);
}
.auth-title-box {
  background: var(--primary);
  padding: 8px;
  text-align: center;
  color: #fff;
}
.rounded {
  border-radius: 4px !important;
}
.table-container {
  width: 100%;
  overflow: auto;
}
.table-container table {
  min-width: 100%;
}
.creditScoreFile {
  max-width: 50px;
  height: 50px;
  cursor: pointer;
}
.creditScoreFile.active {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;
  padding: 8px;
  max-width: 300px;
  height: auto;
}
.user_grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.sidebar {
  width: 200px;
  min-width: 200px;
  height: calc(100vh - 64px);
  overflow-y: auto;
  position: sticky;
  top: 64px;
  left: 0;
  background: #fff;
  z-index: 9;
  transition: var(--transition);
  padding: 8px;
}
.sidebar ul {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 8px;
}
.sidebar ul li {
  display: block;
  padding: 0;
}
.main_div {
  flex: 1;
  padding: 8px;
  overflow-x: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
}
.toggle-bar {
  display: none;
  cursor: pointer;
}
.salaryArrow,
.scoreArrow,
.indexArrow {
  cursor: pointer;
}

.salaryArrow.rotate,
.scoreArrow.rotate,
.indexArrow.rotate {
  transform: rotate(180deg);
}
header a.active {
  background-color: rgba(0, 0, 0, 0.144);
  border-radius: 4px;
}
.active-user li {
  background: var(--primary);
  color: #fff;
}
.password-div {
  width: 100%;
  position: relative;
}
.password-show {
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
}
.password-show * {
  pointer-events: none;
}
.table-background {
  background: #efefef;
}
.count-badge {
  border-radius: 4px;
  background: transparent;
  margin-left: 10px;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
}
.count-badge.active {
  background: var(--primary);
  border: 1px solid var(--primary);
  color: #fff;
}
.top-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.top-inner-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.top-icon-grid {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.shared-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.toggle-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.secret_key_bar {
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: end;
  justify-content: space-between;
  gap: 8px;
}
.secret_key_bar .second {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;
}
.max-300 {
  max-width: 300px;
}
.text-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.logo {
  width: 100px;
}
.count-circle-box {
  width: 25px;
  height: 25px;
  display: grid;
  place-items: center;
  border-radius: 50%;
}
.top-bar-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.user-container {
  width: 100%;
}
.user-name {
  width: 280px;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 70px;
  left: -300px;
  background: #fff;
  z-index: 5;
  transition: var(--transition);
}
.user-name.active {
  left: 0;
}
.user-name p {
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid var(--primary);
  transition: var(--transition);
}
.user-name p.active {
  background: var(--primary);
  color: #fff;
}
